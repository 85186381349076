@import './vars.scss';

html,
body {
  margin: 0;
  left: 0;
  top: 0;
}

hr {
  border: 1px solid $light-grey;
}

// generals
.card-image {
  height: 90;
}

.text-value {
  color: green;
}

.text-right {
  text-align: right;
}

// -----------------------------------

// card customSelectFieldStyles

.card-title {
  direction: row;
}

// grid customSelectFieldStyles
.grid-values-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.grid-values-center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  table-layout: auto;
}

// -- tables
.table-left {
  table-layout: fixed;

  margin: {
    top: 20px;
  }
}

.table-rigth {
  table-layout: fixed;

  margin: {
    top: 20px;
  }
}

.table-center {
  table-layout: fixed;
}

@for $var from 4 to 5 {
  h#{$var} {
    line-height: 15;
    padding: 0;
    margin: 0 !important;
  }
}

hr.separate-line {
  border: 0.5px solid rgb(199, 199, 199);
}

hr.separate-rows {
  border: 0.5px solid rgb(233, 230, 230);
}

.content-container {
  min-height: calc(100% - 128px);
  display: grid;
  grid-template-rows: 1fr auto;
}

.footer {
  grid-row-start: 2;
  grid-row-end: 3;
  height: 64px;
  background: white;
  &-logo {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 20px;
  }
}

.grecaptcha-badge {
  z-index: 99999;
  display: block !important;
}
